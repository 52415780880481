import { ELocale } from 'i18n/localeEnum';

export interface IUrlTranslations {
  resort: {
    'cams': string;
    'firsthand': string;
    'historical-snowfall': string;
    'lodging': string;
    'reviews': string;
    'ski-resort': string;
    'skireport': string;
    'skirentals': string;
    'trailmap': string;
    'weather': string;
    'lift-tickets': string,
    'flights': string,
    'cars': string,
  },
  region: {
    'cams': string;
    'ski-resorts': string;
    'skireport': string;
    'open-resorts': string;
    'projected-closing': string;
    'projected-openings': string;
    'lift-tickets': string,
    'lodging': string,
  },
  global: {
    'ski-resorts': string;
    'skireport': string;
    'nearby-resorts': string;
    'index'?: string;
  }
}

export type IResortUrls = keyof IUrlTranslations['resort'];
export type IRegionUrls = keyof IUrlTranslations['region'];
export type IGlobalUrls = keyof IUrlTranslations['global'];

export interface IPortalConfig {
  social: {
    youtube: string,
    facebook: string,
    twitter: string;
    instagram: string;
  },
  links: {
    tos: string;
    privacy: string;
    impressum: string;
    contact: string;
    advertise: string;
    weather: string;
  },
  keys: {
    /**
     * Feedback form id
     */
    typeformId: string;
    /**
     * Tag for Ad Unit Pathes
     */
    dfpTag: string;
  },
  options: {
    currency: string;
    hasNews: boolean;
  }
}

export const portalConfig: Record<ELocale, IPortalConfig> = {
  [ELocale.EN_US]: {
    keys: {
      typeformId: 'Bz2zOcvF',
      dfpTag: 'OTS_COM',
    },
    links: {
      impressum: '', // Not displayed on US
      privacy: '/privacy/',
      tos: '/onthesnow-terms-conditions/',
      contact: 'https://www.onthesnow.com/news/contact-us/',
      advertise: 'https://www.mountainnews.com/',
      weather: '/weather/',
    },
    social: {
      facebook: 'onthesnowfan',
      instagram: 'onthesnow',
      twitter: 'onthesnow',
      youtube: 'OnTheSnowcom',
    },
    options: {
      currency: 'USD',
      hasNews: true,
    },
  },
  [ELocale.DE_DE]: {
    keys: {
      typeformId: 'zI3A2w99',
      dfpTag: 'SI_DE',
    },
    links: {
      impressum: '/impressum/', // Not displayed on US
      tos: '/geschaftsbedingungen/',
      privacy: '/datenschutz/',
      contact: 'https://www.skiinfo.de/news/contact-us/',
      advertise: 'https://www.mountainnews.de/',
      weather: '/wetter/',
    },
    social: {
      facebook: 'skiinfo',
      instagram: 'skiinfo.de',
      twitter: 'Skiinfo',
      youtube: 'mountains2b',
    },
    options: {
      currency: 'EUR',
      hasNews: true,
    },
  },
  [ELocale.EN_GB]: {
    keys: {
      typeformId: 'Nvbnokb3',
      dfpTag: 'OTS_UK',
    },
    links: {
      impressum: '/legal-notice/', // Not displayed on US
      privacy: '/privacy/',
      tos: '/onthesnow-terms-conditions/',
      contact: 'https://www.onthesnow.co.uk/news/contact-us/',
      advertise: 'https://www.mountainnews.com/',
      weather: '/weather/',
    },
    social: {
      facebook: 'pages/Skiinfocouk/169153356796',
      instagram: 'onthesnowuk',
      twitter: 'OnTheSnowUK',
      youtube: 'OnTheSnowcom',
    },
    options: {
      currency: 'GBP',
      hasNews: true,
    },
  },
  [ELocale.FR_FR]: {
    keys: {
      typeformId: 'ymS288Uo',
      dfpTag: 'SI_FR',
    },
    links: {
      impressum: '/mentions-legales/',
      privacy: '/vie-privee/',
      tos: '/conditions-generales/',
      contact: 'https://www.skiinfo.fr/news/contact-us/',
      advertise: 'https://www.mountainnews.fr/',
      weather: '/meteo/',
    },
    social: {
      facebook: 'SkiinfoFrance',
      instagram: 'skiinfo.fr',
      twitter: 'SkiinfoFrance',
      youtube: 'skiinfofr',
    },
    options: {
      currency: 'EUR',
      hasNews: true,
    },
  },
  [ELocale.IT_IT]: {
    keys: {
      typeformId: 'fQM9bDcc',
      dfpTag: 'SI_IT',
    },
    links: {
      impressum: '/note-legali/',
      privacy: '/privacy/',
      tos: '/termini-di-utilizzo/',
      contact: 'https://www.skiinfo.it/news/contact-us/',
      advertise: 'https://www.mountainnews.it/',
      weather: '/meteo/',
    },
    social: {
      facebook: 'skiinfo.italy',
      instagram: 'skiinfo.it',
      twitter: 'skiinfoit',
      youtube: 'SKIINFOIT',
    },
    options: {
      currency: 'EUR',
      hasNews: true,
    },
  },
  [ELocale.PL_PL]: {
    keys: {
      typeformId: 'pbkggb7h',
      dfpTag: 'SI_PL',
    },
    links: {
      impressum: '/nota-prawna/',
      privacy: '/polityka-prywatnosci/',
      tos: '/warunki-korzystania/',
      contact: 'https://www.skiinfo.pl/news/contact-us/',
      advertise: 'https://www.mountainnews.com/',
      weather: '/pogoda/',
    },
    social: {
      facebook: 'skiinfo.polska',
      instagram: 'skiinfo.pl',
      twitter: 'SkiinfoPL',
      youtube: 'OnTheSnowcom',
    },
    options: {
      currency: 'EUR',
      hasNews: true,
    },
  },
  [ELocale.CS_CZ]: {
    keys: {
      typeformId: 'oLOPmHf9',
      dfpTag: 'SI_CZ',
    },
    links: {
      impressum: '/imprint/',
      privacy: '/ochrana-soukromi/',
      tos: '/podminky/',
      contact: 'https://www.onthesnow.cz/news/contact-us/',
      advertise: 'https://www.mountainnews.com/',
      weather: '/pocasi/',
    },
    social: {
      facebook: 'OnTheSnow.cz',
      instagram: 'OnTheSnowCZ',
      twitter: 'OnTheSnowCZ',
      youtube: 'OnTheSnowcom',
    },
    options: {
      currency: 'CZK',
      hasNews: true,
    },
  },
  [ELocale.SK_SK]: {
    keys: {
      typeformId: 'kpflMe9c',
      dfpTag: 'SI_SK',
    },
    links: {
      impressum: '/imprint/',
      privacy: '/ochrana-sukromia/',
      tos: '/podmienky-pouzivania/',
      contact: 'https://www.onthesnow.sk/news/contact-us/',
      advertise: 'https://www.mountainnews.com/',
      weather: '/pocasie/',
    },
    social: {
      facebook: 'OnTheSnow.sk',
      instagram: 'OnTheSnow.sk',
      twitter: 'OnTheSnowSK',
      youtube: 'OnTheSnowcom',
    },
    options: {
      currency: 'EUR',
      hasNews: true,
    },
  },
  [ELocale.NL_NL]: {
    keys: {
      typeformId: 'B29FUeoG',
      dfpTag: 'SI_NL',
    },
    links: {
      impressum: '/juridische-kennisgeving/',
      privacy: '/privacy/',
      tos: '/gebruiksvoorwaarden/',
      contact: 'https://www.skiinfo.nl/news/contact-us/',
      advertise: 'https://www.mountainnews.com/',
      weather: '',
    },
    social: {
      facebook: 'pages/skiinfonl/247285392953',
      instagram: 'skiinfo.fr',
      twitter: 'SkiinfoFrance',
      youtube: 'skiinfofr',
    },
    options: {
      currency: 'EUR',
      hasNews: false,
    },
  },
  [ELocale.NN_NO]: {
    keys: {
      typeformId: 'yxADoHXe',
      dfpTag: 'SI_NO',
    },
    links: {
      impressum: '/ansvarserklaring/',
      privacy: '/personvernpolicy/',
      tos: '/vilkar/',
      contact: 'https://www.skiinfo.no/news/contact-us/',
      advertise: 'https://www.mountainnews.com/',
      weather: '',
    },
    social: {
      facebook: 'skiinfonorge',
      instagram: 'onthesnow',
      twitter: 'SkiinfoNorge',
      youtube: 'OnTheSnowcom',
    },
    options: {
      currency: 'NOK',
      hasNews: false,
    },
  },
  [ELocale.ES_ES]: {
    keys: {
      typeformId: 'QFKDViY3',
      dfpTag: 'SI_ES',
    },
    links: {
      impressum: '/aviso-legal/',
      privacy: '/privacidad/',
      tos: '/conditiones/',
      contact: 'https://www.es.skiinfo.com/news/contact-us/',
      advertise: 'https://www.mountainnews.com/',
      weather: '',
    },
    social: {
      facebook: 'pages/Skiinfoes/133314619969',
      instagram: 'onthesnow',
      twitter: 'onthesnow',
      youtube: 'OnTheSnowcom',
    },
    options: {
      currency: 'EUR',
      hasNews: false,
    },
  },
  [ELocale.SV_SE]: {
    keys: {
      typeformId: 'ACvSTIPj',
      dfpTag: 'SI_SE',
    },
    links: {
      impressum: '/juridisk-information/',
      privacy: '/dataskyddspolicy/',
      tos: '/anvandarvillkor/',
      contact: 'https://www.skiinfo.se/news/contact-us/',
      advertise: 'https://www.mountainnews.com/',
      weather: '',
    },
    social: {
      facebook: 'pages/Skiinfo-Sverige/168843323214',
      instagram: 'onthesnow',
      twitter: 'onthesnow',
      youtube: 'OnTheSnowcom',
    },
    options: {
      currency: 'EUR',
      hasNews: false,
    },
  },
  [ELocale.DA_DK]: {
    keys: {
      typeformId: 'V3UJS74d',
      dfpTag: 'SI_DK',
    },
    links: {
      impressum: '/firmainformation/',
      privacy: '/privatlivspolitik/',
      tos: '/vilkar/',
      contact: 'https://www.skiinfo.dk/news/contact-us/',
      advertise: 'https://www.mountainnews.com/',
      weather: '',
    },
    social: {
      facebook: 'pages/Skiinfo-Danmark/163375874774',
      instagram: 'onthesnow',
      twitter: 'onthesnow',
      youtube: 'OnTheSnowcom',
    },
    options: {
      currency: 'DKK',
      hasNews: false,
    },
  },
};
